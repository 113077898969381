<!-- studentInfo -->
<template>
  <div class="content has_breadcrumb">
    <!--  section-info1 -->
    <section-info1
      :data="formDataList"
      :renderPass="renderPass"
      title="Company info"
      :iconEdit="false"
      :isShowLabel="true"
      :inline="true"
      :readonly.sync="readonly"
      @submit="submitEdit"
      @cancel="handleCancel"
      @input="input"
      v-loading="loading"
    >
      <template slot="renderHtml" slot-scope="scope">
        <div v-if="scope.data.key === 'country'" class="select-hei">
          <baseGGSelect
            v-model="scope.data.value"
            :placeholder="scope.data.placeholder"
            :key="scope.data.key"
            :ref="scope.data.key"
            :inline="true"
          >
            <md-option
              v-for="(item, index) in scope.data.roleList"
              :key="index"
              :value="item.value"
            >
              {{ item.label }}
            </md-option>
          </baseGGSelect>
        </div>
        <gg-input
          v-else-if="scope.data.key == 'email'"
          :ref="scope.data.key"
          v-model="scope.data.value"
          :error="emailErrorObj"
          :inline="true"
        />
        <div class="not-readonly-content" style="float: left;" v-else-if="scope.data.key == 'tel'">
          <PhoneNumber
            v-model="scope.data.value"
            :placeholder="scope.data.placeholder"
            :ref="scope.data.key"
          ></PhoneNumber>
        </div>
      </template>
      <div slot="tip">* indicates a required field</div>
    </section-info1>
  </div>
</template>

<script>
import { Ajax } from "@/common";
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      formDataList: [
        {
          placeholder: "Name *",
          key: "companyName",
          value: "",
          autofocus: true,
          reg: /\S/,
          errorText: "Name is required",
        },
        {
          placeholder: "Registration no. *",
          key: "registrationNo",
          value: "",
          reg: /\S/,
          errorText: "Registration no. is required",
        },
        {
          placeholder: "Address *",
          key: "address",
          value: "",
          reg: /\S/,
          errorText: "Address is required",
        },
        {
          placeholder: "Address line 2",
          key: "addressLine2",
          value: "",
          // reg: /^[\S]*$/,
          // errorText: "",
        },
        {
          placeholder: "Postal code *",
          key: "postalCode",
          value: "",
          reg: /\S/,
          errorText: "Postal code is required",
        },
        {
          placeholder: "Country *",
          key: "country",
          value: "",
          roleList: [
            // { value: "Singepore", label: "Singepore" },
            // { value: "China", label: "China" },
            // { value: "English", label: "English" },
          ],
          renderHtml: true,
        },
        {
          placeholder: "Email",
          key: "email",
          value: "",
          renderHtml: true,
          // reg: /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/,
          // errorText: "Invalid email address",
        },
        {
          placeholder: "Phone",
          key: "tel",
          value: {
            countryCode: "+65",
            phoneNumber: "",
          },
          renderHtml: true,
        },
      ],
      // breadcrumbData: [],
      renderPass: true,
      slotData: ["country", "tel"],
      isDisabled: true,
      readonly: false,
      companyInfo: {
        companyName: "",
        registrationNo: "",
        address: "",
        addressLine2: "",
        email: "",
        tel: {},
      },
      snackbar: {
        isShow: false,
        content: "",
      },
      loading: false,
	  creater:""
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
    emailErrorObj() {
      let email = this.formDataList.find((item) => item.key === "email").value;
      if (email === ""  || email == null) {
        return {
          show: false,
          message: "",
        };
      }

      let isPass = /^(\w)+(\.\w+)*@(\w)+((\.\w+)+)$/.test(email);
      return {
        show: !isPass,
        message: "Email address is invalid",
      };
    },
  },
  watch: {
    formDataList: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          let aaa = this.slotData.every(
            (item) => this.$refs[item] && !!this.$refs[item].value
          );
          // let emailError = this.$refs.email1
          let telPass =
            this.$refs.tel &&
            !this.$refs.tel.isError.show &&
            !!this.$refs.tel.value.phoneNumber;
          this.renderPass = aaa && telPass;
          console.log("renderPass1:", aaa);
          console.log("renderPass2:", telPass);
        });
      },
    },
  },
  methods: {
    _getData() {
      let data = {
        userId: this.user.userId,
      };
      this.loading = true;
      Ajax.get("/usermanage/company/selectCompanyByUserId", data)
        .then((res) => {
          let response = res.data;
          this.loading = false;
		 
		  if(this.user.userType==0){
			  this.creater=this.user.userId
		  }else{
			  this.creater=response.creater 
		  }
		 
		  this.getSettingList("general", "country");
          this.formDataList.forEach((item) => {
            item.value = response[item.key];
            if (item.key == "tel") {
              item.value = {
                countryCode: response.countryCode,
                phoneNumber: response.phoneNumber,
              };
            }
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    input(res) {
      console.log(res);
    },
    handleCheck(val) {
      this.checkedData = val;
      // console.log(this.locations);
    },
    handleCancel() {
      this.readonly = false;
      this.$router.back();
    },
    submitEdit() {
      this.readonly = false;

      var formData = {
        userId: this.user.userId,
        id: this.$route.query.id,
        companyName: "",
        registrationNo: "",
        address: "",
        addressLine2: "",
        postalCode: "",
        email: "",
        country: "",
        countryCode: "",
        phoneNumber: "",
      };
      this.loading = true;
      this.formDataList.forEach((item) => {
        if (item.key == "tel") {
          formData["countryCode"] = item.value.countryCode;
          formData["phoneNumber"] = item.value.phoneNumber;
        } else {
          formData[item.key] = item.value;
        }
      });
      Ajax.post("/usermanage/company/updateCompany", formData).then((res) => {
        let response = res.data;
        this.handleSuspend(res.message);
        this.loading = false;
        this.$router.go(-1);
      });
    },
    handleSuspend(content) {
      this.snackbar.content = content;
      this.snackbar.isShow = true;
    },
    getSettingList(dataLevel, dataType) {
		console.log(this.formDataList)
      let params = {
        dataLevel: dataLevel,
        dataType: dataType,
		userId:this.creater,
      };
      Ajax.post("/usermanage/setting/getList", params)
        .then((res) => {
          console.info(res.data);
          let arr = [];
          res.data.forEach((item1) => {
            this.formDataList.forEach((item) => {
              let obj = {};
              if (item.key === "country") {
                obj = { value: item1.value, label: item1.value };
                arr.push(obj);
              }
            });
          });
          this.formDataList.find(
            (item) => item.key === "country"
          ).roleList = arr;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    // this.breadcrumbData = this.$store.state.breadcrumb.breadcrumbData;
    this._getData();
    
  },
};
</script>
<style lang='less' scoped>
.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  /deep/.float {
    width: calc(50% - 10px);
    flex-basis: calc(50% - 10px);
    flex-grow: 0;
    flex-shrink: 0;
  }
}
.not-readonly-content {
  flex-basis: calc(100% - 140px);
  width: calc(100% - 140px);
  padding: 30px 0 0 !important;
  .phone-number-content {
    width: 100%;
  }
}
/deep/.not-readonly-content{
	max-width: 900px!important;
}
</style>